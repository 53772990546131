<template>
  <div class="shops-table">
    <WarehouseManagementHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">

      <!--<WarehouseManagementFilter-->
          <!--:showFilter="showFilter"-->
          <!--@close="closeFilter"-->
          <!--:filterId="filterId"-->
          <!--:filterName="filterName"-->
      <!--/>-->

      <WarehouseManagementTable @showMore="showMore"/>
    </div>

  </div>
</template>

<script>
  import WarehouseManagementHead from "./WarehouseManagementHead/WarehouseManagementHead";
  // import WarehouseManagementFilter from "./WarehouseManagementFilter/WarehouseManagementFilter";
  import WarehouseManagementTable from "./WarehouseManagementTable/WarehouseManagementTable";

  export default {
    name: "WarehouseManagementTablePage",
    components: {
      WarehouseManagementHead,
      // WarehouseManagementFilter,
      WarehouseManagementTable,
    },

    data() {
      return {
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: this.$store.getters.getWarehousesForPage,
        page: 1,
      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      filter(next = false) {
        // Generate filter URL
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        url = url + myQuery.limit(this.forPage).page(parseInt(this.page)).url().split('?')[1]

        // True if get next page
        if(next) this.$store.commit('setNextWarehousesPage', true)

        // save filter params in store
        this.$store.commit('setShopsFilter', window.location.search)

        this.$store.dispatch('fetchWarehouses', url).then(() => {
          // skip get next page
          this.$store.commit('setNextWarehousesPage', false)
        })
      },

      showMore() {
        this.$route.query.page === undefined ?
          this.$router.push({ path: '', query: {page: this.page + this.onePage, count: this.forPage}}) :
          this.$router.push({ path: '', query: {page: parseInt(this.$route.query.page) + this.onePage, count: this.forPage}})
        this.filter(true)
      },
    }
  }
</script>

<style lang="scss">

</style>
