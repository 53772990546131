<template>
  <div class="fragment" v-if="$store.getters.getWarehouses.length > 0">
    <div class="site-table-wrap express-order-table" v-if="!isMobileFunc()"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span class="admin-edit" @click="editTranslate([
      'warehouse_code',
      'common_consolidation',
      'common_Active',
      'warehouseManagement_manage',
      ])"></span>
      <table class="site-table">
        <thead>
        <tr>
          <th>
            <span>
              {{$t('warehouse_name.localization_value.value')}}
            </span>
          </th>
          <th>
            <span>
              {{$t('warehouse_code.localization_value.value')}}
            </span>
          </th>
          <th>
            <span>
              Fedex {{$t('common_consolidation.localization_value.value')}}
            </span>
          </th>
          <th>
            <span>
              DHL {{$t('common_consolidation.localization_value.value')}}
            </span>
          </th>
<!--          <th>-->
<!--            <span>-->
<!--              Landmark {{$t('common_consolidation.localization_value.value')}}-->
<!--            </span>-->
<!--          </th>-->
          <th>
            <span>
                {{$t('common_Active.localization_value.value')}}
            </span>
          </th>
          <th>
            <span>
              {{$t('warehouseManagement_manage.localization_value.value')}}
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in $store.getters.getWarehouses" :key="index">
          <td>{{item.currentTranslate.name}}</td>
          <td>{{item.code}}</td>
          <td>
            <StatusIcoBtn :type="item.consolidation_fedex === 1 ? 'active' : 'declined'" class="mr-1"/>
            <span  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_yes', 'warehouseManagement_no'])"></span>
              {{item.consolidation_fedex === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </span>
          </td>
          <td>
            <StatusIcoBtn :type="item.consolidation_dhl === 1 ? 'active' : 'declined'" class="mr-1"/>
            <span  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_yes', 'warehouseManagement_no'])"></span>
              {{item.consolidation_dhl === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </span>
          </td>
<!--          <td>-->
<!--            <StatusIcoBtn :type="item.consolidation_landmark === 1 ? 'active' : 'declined'" class="mr-1"/>-->
<!--            <span  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <span class="admin-edit" @click="editTranslate(['warehouseManagement_yes', 'warehouseManagement_no'])"></span>-->
<!--              {{item.consolidation_landmark === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}-->
<!--            </span>-->
<!--          </td>-->
          <td>
            <StatusIcoBtn :type="item.enable === 1 ? 'active' : 'declined'" class="mr-1"/>
            <span  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_yes', 'warehouseManagement_no'])"></span>
              {{item.enable === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </span>
          </td>
          <td>
            <div class="table-right table-manage-list">
              <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                <template slot="item">
                  <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          @click.native="editWarehouse(item.id)"
                  />
                </template>
                <template slot="item">
                  <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="deleteWarehouse(item.id)"
                  />
                </template>
              </ManagerButton>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <div class="table-card" v-else>
      <div class="table-card__list">
        <div class="table-card__item"
             v-for="(item, index) in $store.getters.getWarehouses"
             :key="index"
        >
          <WarehouseManagementTableMobile
                  :item="item"
                  @deleteWarehouse="deleteWarehouse(item.id)"
                  @editWarehouse="editWarehouse(item.id)"
          />
        </div>
      </div>
    </div>

    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner" v-if="$store.getters.getWarehousesCommonList.next_page_url !== null">
        <ShowMore class="table-bottom-btn__right" @click.native="$emit('showMore')"
                  :count="$store.getters.getWarehousesCommonList.total - $store.getters.getShopsForPage < $store.getters.getWarehousesForPage ?
                  $store.getters.getWarehousesCommonList.total - $store.getters.getWarehousesForPage:
                  $store.getters.getWarehousesForPage"
        />


      </div>
    </div>
  </div>

  <div class="fragment" v-else>
    <template v-if="$store.getters.getLoadingWarehouses === false && $store.getters.getWarehouses.length === 0">
      <NoResult
              :countFilterParams="0"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
      />
    </template>
  </div>

</template>

<script>
  import ManagerButton from "../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../UI/buttons/LinkButton/LinkButton";
  import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ShowMore from "../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../mixins/mobileFunctions'
  import WarehouseManagementTableMobile from "./WarehouseManagementTableMobile/WarehouseManagementTableMobile";
  import NoResult from "../../../../../coreComponents/NoResult/NoResult";

  export default {
    name: "WarehouseManagementTable",

    mixins: [mixinDetictingMobile],

    components: {
      ManagerButton,
      LinkButton,
      StatusIcoBtn,
      ShowMore,
      WarehouseManagementTableMobile,
      NoResult,
    },

    data() {
      return {
        items: [
          {
            item: 'empty',
            show: false,
            name: 'Kyiv',
            code: 'KIEV01',
            fedexConsolidationTxt: 'Yes',
            fedexConsolidationStatus: 'active',
            DHLConsolidation: 'Yes',
            DHLConsolidationStatus: 'active'
          },
          {
            item: 'empty',
            show: false,
            name: 'Dnipro',
            code: 'DNEPR01',
            fedexConsolidationTxt: 'Yes',
            fedexConsolidationStatus: 'active',
            DHLConsolidation: 'Yes',
            DHLConsolidationStatus: 'active'
          },
          {
            item: 'empty',
            show: false,
            name: 'Minsk',
            code: 'MINSK01',
            fedexConsolidationTxt: 'No',
            fedexConsolidationStatus: 'declined',
            DHLConsolidation: 'Yes',
            DHLConsolidationStatus: 'active'
          },
        ]
      }
    },


    methods: {
      editWarehouse(id) {
        this.$router.push(this.$store.getters.GET_PATHS.mainSettingsWarehouseManagement + '/' + id)
      },

      deleteWarehouse(id) {

        let text = {
          title: 'shops_deleteShopWarehouse',
          txt: 'shops_deleteShopWarehouseQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteWarehouses', id).then(() => {
            setTimeout(() => {
              let warehouses = this.$store.getters.getWarehouses
              warehouses.map((item, index) => {
                if(item.id === id) {
                  warehouses.splice(index, 1)
                }
              })

              this.openNotify('success', 'common_notificationRecordDeleted')
            }, 200)
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      }

    }
  }
</script>

<style lang="scss">

</style>
