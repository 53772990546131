<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <!--<div class="content-tabs">-->
        <!--<div class="content-tabs__item active">-->
          <!--All-->
        <!--</div>-->
      <!--</div>-->

      <!--<div class="content-top-line-wrap">-->
        <!--<ToggleFilterButton @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />-->
      <!--</div>-->
    </div>

    <div class="light-bg-block">
      <!--<div class="btn-left-block">-->
        <!--<DefaultCheckbox-->
          <!--:label="'Select all'"-->
        <!--/>-->
      <!--</div>-->
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_AddNew'])"></span>
        <router-link :to="$store.getters.GET_PATHS.mainSettingsWarehouseManagementCreate">
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  // import ToggleFilterButton from "../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "WarehouseManagementHead",
    components: {
      MainButton,
      // ToggleFilterButton,
      PlusIconSVG,
      // DefaultCheckbox,
    },

    props: [
      'showFilter',
    ],
  }
</script>

<style scoped>

</style>
