<template>
  <div class="table-card__item-content"
       :class="{show : itemActive}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{item.currentTranslate.name}}
        </div>
        <div class="table-card__item-head-txt">
          {{ item.code }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="itemActive"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('warehouse_name.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.currentTranslate.name}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('warehouse_code.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.code }}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              Fedex {{$t('common_consolidation.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <StatusIcoBtn :type="item.consolidation_fedex === 1 ? 'active' : 'declined'" class="mr-1"/>
              {{item.consolidation_fedex === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              DHL {{$t('common_consolidation.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <StatusIcoBtn :type="item.consolidation_dhl === 1 ? 'active' : 'declined'" class="mr-1"/>
              {{item.consolidation_dhl === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </div>
          </div>
        </div>
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              Landmark {{$t('common_consolidation.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <StatusIcoBtn :type="item.consolidation_landmark === 1 ? 'active' : 'declined'" class="mr-1"/>
              {{item.consolidation_landmark === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('common_Active.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <StatusIcoBtn :type="item.enable === 1 ? 'active' : 'declined'" class="mr-1"/>
              {{item.enable === 1 ? $t('warehouseManagement_yes.localization_value.value') : $t('warehouseManagement_no.localization_value.value')}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    @click.native="$emit('editWarehouse')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('deleteWarehouse')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="itemActive = !itemActive"
      >
        {{$t('common_mobileTableShowMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "WarehouseManagementTableMobile",

    components: {
      StatusIcoBtn,
      LinkButton
    },

    props: ['item'],

    data() {
      return {
        itemActive: false,
      }
    }
  }
</script>

<style scoped>

</style>
