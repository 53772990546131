<template>
  <CardRightBlock>
    <template slot="header">
      <div class="order-create__head">
        <div class="order-create__head-line">
          <div class="order-create__header-col admin-edit-item">
            <span class="admin-edit" @click="editTranslate(['warehouseManagement_backToWarehouse'])"></span>
            <router-link :to="$store.getters.GET_PATHS.mainSettingsWarehouseManagement" class="order-create__head-back">
              <LinkBack
                      :value="$t(`warehouseManagement_backToWarehouse.localization_value.value`)"
              />
            </router-link>
          </div>
        </div>
        <div class="order-create__head-title admin-edit-item">
          <span class="admin-edit" @click="editTranslate(['common_edit'])"></span>
          {{$t('common_edit.localization_value.value')}}
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="order-create__section">

        <div class="admin-edit-item">
          <span class="admin-edit" @click="editTranslate(['warehouseManagement_masterData'])"></span>
          <TitleHorizontal
                  :value="$t('warehouseManagement_masterData.localization_value.value')"
          />
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item" v-if="$store.getters.getWarehousesType.length > 0">
            <span class="admin-edit" @click="editTranslate(['warehouseManagement_type'])"></span>
            <DefaultSelect
                    :options="$store.getters.getWarehousesType"
                    :label="$t('warehouseManagement_type.localization_value.value')"
                    v-bind:class="{'ui-no-valid': validation.selectedType}"
                    :error="validation.selectedType"
                    :errorTxt="serverError ? validationTxt.selectedType : $t(`${validationTranslate.selectedType}.localization_value.value`)"
                    @change="changeTypeWarehouse"
                    :optionsLabel="'name'"
                    :selected="selectedType"
            />
          </div>
          <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
            <span class="admin-edit" @click="editTranslate(['warehouseManagement_code'])"></span>
            <DefaultInput
                    :label="$t('warehouseManagement_code.localization_value.value')"
                    v-bind:class="{'ui-no-valid': validation.code}"
                    :error="validation.code"
                    :errorTxt="serverError ? validationTxt.code : $t(`${validationTranslate.code}.localization_value.value`)"
                    :type="'text'"
                    v-model="code"
                    :disabled="true"
            />
          </div>
          <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
            <span class="admin-edit" @click="editTranslate(['warehouseManagement_sort'])"></span>
            <DefaultInput
                    :label="$t('warehouseManagement_sort.localization_value.value')"
                    v-bind:class="{'ui-no-valid': validation.customSort}"
                    :error="validation.customSort"
                    :errorTxt="serverError ? validationTxt.customSort : $t(`${validationTranslate.customSort}.localization_value.value`)"
                    :type="'text'"
                    v-model="customSort"
            />
          </div>
        </div>

        <template v-if="translation.length > 0">
          <div class="order-create__row custom-row">

            <template
                    v-for="(item, index) in $store.getters.GET_LANGUAGES"
            >


              <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100" :key="index + 'warehouseName'">
                <div class="mb-3" >
                  <div class="section-label mb-0">
                    {{ item.name }}
                  </div>
                </div>
                <div class="admin-edit-item">
                  <span class="admin-edit" @click="editTranslate(['warehouseManagement_senderInfo'])"></span>
                <DefaultInput
                          :label="$t('warehouseManagement_warehouseName.localization_value.value')"
                        v-bind:class="{'ui-no-valid': validation['name_' + item.id]}"
                        :error="validation['name_' + item.id]"
                        :errorTxt="serverError ? validationTxt['name_' + item.id] : $t(`${validationTranslate['name_' + item.id]}.localization_value.value`)"
                        :type="'text'"
                        v-model="translation[index].name"
                />
                </div>
              </div>
            </template>

          </div>
        </template>

        <template v-if="translation.length > 0">
          <div class="order-create__row custom-row">

            <div class="custom-col mb-3 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_senderInfo'])"></span>
              <TitleHorizontal
                      :value="$t('warehouseManagement_senderInfo.localization_value.value')"
              />
            </div>

            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item" v-if="optionsCountries.length > 0">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_country'])"></span>
              <DefaultSelect
                      v-bind:class="{'ui-no-valid': validation.selectedCountry}"
                      :optionsLabel="'label'"
                      :error="validation.selectedCountry"
                      :errorTxt="serverError ? validationTxt.selectedCountry : $t(`${validationTranslate.selectedCountry}.localization_value.value`)"
                      :options="optionsCountries"
                      @change="changeCountry"
                      :selected="selectedCountry.label"
                      :label="$t('warehouseManagement_country.localization_value.value')"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_senderName'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_senderName.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.sender_name}"
                      :error="validation.sender_name"
                      :errorTxt="serverError ? validationTxt.sender_name : $t(`${validationTranslate.sender_name}.localization_value.value`)"
                      :type="'text'"
                      v-model="sender_name"
              />
            </div>


            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_phone'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_phone.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.phone}"
                      :error="validation.phone"
                      :errorTxt="serverError ? validationTxt.phone : $t(`${validationTranslate.phone}.localization_value.value`)"
                      :type="'text'"
                      v-model="phone"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_email'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_email.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.email}"
                      :error="validation.email"
                      :errorTxt="serverError ? validationTxt.email : $t(`${validationTranslate.email}.localization_value.value`)"
                      :type="'text'"
                      v-model="email"
              />
            </div>

            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_zipCodePostalCode'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_zipCodePostalCode.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.zipCode}"
                      :error="validation.zipCode"
                      :errorTxt="serverError ? validationTxt.zipCode : $t(`${validationTranslate.zipCode}.localization_value.value`)"
                      :type="'text'"
                      v-model="zipCode"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_zipCodePostalCode'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_senderCity.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.sender_city}"
                      :error="validation.sender_city"
                      :errorTxt="serverError ? validationTxt.sender_city : $t(`${validationTranslate.sender_city}.localization_value.value`)"
                      :type="'text'"
                      v-model="sender_city"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_senderAddress'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_senderAddress.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.sender_address}"
                      :error="validation.sender_address"
                      :errorTxt="serverError ? validationTxt.sender_address : $t(`${validationTranslate.sender_address}.localization_value.value`)"
                      :type="'text'"
                      v-model="sender_address"
              />
            </div>
          </div>


          <div class="order-create__row custom-row">
            <div class="custom-col mb-3 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_letterInfo'])"></span>
              <TitleHorizontal
                      :value="$t('warehouseManagement_letterInfo.localization_value.value')"
              />
            </div>


            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_letterFullName'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_letterFullName.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.letter_full_name}"
                      :error="validation.letter_full_name"
                      :errorTxt="serverError ? validationTxt.letter_full_name : $t(`${validationTranslate.letter_full_name}.localization_value.value`)"
                      :type="'text'"
                      v-model="letter_full_name"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_letterShortName'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_letterShortName.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.letter_short_name}"
                      :error="validation.letter_short_name"
                      :errorTxt="serverError ? validationTxt.letter_short_name : $t(`${validationTranslate.letter_short_name}.localization_value.value`)"
                      :type="'text'"
                      v-model="letter_short_name"
              />
            </div>

            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_letterAddress'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_letterAddress.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation.letter_address}"
                      :error="validation.letter_address"
                      :errorTxt="serverError ? validationTxt.letter_address : $t(`${validationTranslate.letter_address}.localization_value.value`)"
                      :type="'text'"
                      v-model="letter_address"
              />
            </div>


          </div>



          <div class="order-create__row custom-row">
            <div class="order-create__col custom-col flex-column">

              <span class="admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['common_consolidation'])"></span>
                <DefaultCheckbox
                        :label="'Fedex ' + $t('common_consolidation.localization_value.value')"
                        class="mb-2"
                        v-model="isFedexConsolidation"
                />
              </span>
              <span class="admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['common_consolidation'])"></span>
                <DefaultCheckbox
                        :label="'Dhl' + $t('common_consolidation.localization_value.value')"
                        class="mb-2"
                        v-model="isDhlConsolidation"
                />
              </span>
              <span class="admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_showInDHL'])"></span>
                <DefaultCheckbox
                        :label="$t('warehouseManagement_showInDHL.localization_value.value')"
                        class="mb-2"
                        v-model="isShowInOrders"
                />
              </span>



            </div>
          </div>



          <div class="order-create__row custom-row">
            <div class="custom-col mb-3 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_InfoForCard'])"></span>
              <TitleHorizontal
                      :value="$t('warehouseManagement_InfoForCard.localization_value.value')"
              />
            </div>
          </div>

          <div class="order-create__row custom-row"
               v-for="(item, index) in $store.getters.GET_LANGUAGES"
               :key="index + 'warehouse'">

            <div class="custom-col mb-3">
              <div class="section-label mb-0">
                {{ item.name }}
              </div>
            </div>

            <div class="order-create__col custom-col custom-col--50 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_warehouseTitle'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_warehouseTitle.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation['warehouse_card_title_' + item.id]}"
                      :error="validation['warehouse_card_title_' + item.id]"
                      :errorTxt="serverError ? validationTxt['warehouse_card_title_' + item.id] : $t(`${validationTranslate['warehouse_card_title_' + item.id]}.localization_value.value`)"
                      :type="'text'"
                      v-model="translation[index].warehouse_card_title"
              />
            </div>
            <div class="order-create__col custom-col custom-col--50 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_warehouseRepresentative'])"></span>
              <DefaultInput
                      :label="$t('warehouseManagement_warehouseRepresentative.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation['warehouse_card_representative_' + item.id]}"
                      :error="validation['warehouse_card_representative_' + item.id]"
                      :errorTxt="serverError ? validationTxt['warehouse_card_representative_' + item.id] : $t(`${validationTranslate['warehouse_card_representative_' + item.id]}.localization_value.value`)"
                      :type="'text'"
                      v-model="translation[index].warehouse_card_representative"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_warehouseAddress'])"></span>
              <TextareaDefault
                      :label="$t('warehouseManagement_warehouseAddress.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation['warehouse_card_address_' + item.id]}"
                      :error="validation['warehouse_card_address_' + item.id]"
                      :errorTxt="serverError ? validationTxt['warehouse_card_address_' + item.id] : $t(`${validationTranslate['warehouse_card_address_' + item.id]}.localization_value.value`)"
                      v-model="translation[index].warehouse_card_address"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_warehousePhone'])"></span>
              <TextareaDefault
                      :label="$t('warehouseManagement_warehousePhone.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation['warehouse_card_phone_' + item.id]}"
                      :error="validation['warehouse_card_phone_' + item.id]"
                      :errorTxt="serverError ? validationTxt['warehouse_card_phone_' + item.id] : $t(`${validationTranslate['warehouse_card_phone_' + item.id]}.localization_value.value`)"
                      v-model="translation[index].warehouse_card_phone"
              />
            </div>
            <div class="order-create__col custom-col custom-col--33 custom-col--xl-50 custom-col--xs-100 admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['warehouseManagement_warehouseHours'])"></span>
              <TextareaDefault
                      :label="$t('warehouseManagement_warehouseHours.localization_value.value')"
                      v-bind:class="{'ui-no-valid': validation['warehouse_card_working_hours_' + item.id]}"
                      :error="validation['warehouse_card_working_hours_' + item.id]"
                      :errorTxt="serverError ? validationTxt['warehouse_card_working_hours_' + item.id] : $t(`${validationTranslate['warehouse_card_working_hours_' + item.id]}.localization_value.value`)"
                      v-model="translation[index].warehouse_card_working_hours"
              />
            </div>
          </div>
        </template>


        
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col flex-column admin-edit-item">
            <span class="admin-edit" @click="editTranslate(['common_Active'])"></span>
            <DefaultCheckbox
                    :label="$t('common_Active.localization_value.value')"
                    class="mb-2"
                    v-model="enable"
            />
          </div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="order-create__footer">
        <div class="order-create__footer-link admin-edit-item">
          <span class="admin-edit" @click="editTranslate(['common_cancel'])"></span>
          <router-link :to="$store.getters.GET_PATHS.mainSettingsWarehouseManagement"
                       class="site-link site-link--alt"
          >
            {{$t('common_cancel.localization_value.value')}}
          </router-link>

        </div>



        <div class="order-create__footer-btn ml-auto admin-edit-item">
          <span class="admin-edit" @click="editTranslate(['common_saveChange'])"></span>
          <MainButton
              class="order-create__footer-btn-i wfc"
              :value="$t('common_saveChange.localization_value.value')"
              @click.native="save"
          />
        </div>
      </div>
    </template>
  </CardRightBlock>
</template>

<script>
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {validation} from "../../../../../../services/validation";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";

  export default {
    name: "WarehouseManagementEditing",
    components: {
      LinkBack,
      MainButton,
      DefaultInput,
      DefaultCheckbox,
      DefaultSelect,
      CardRightBlock,
      TextareaDefault,
      TitleHorizontal,
    },

    data(){
      return {
        zipCode: '',
        phone: '',
        email: '',
        code: '',
        customSort: '',
        selectedCountry: '',
        selectedType: '',

        name: '',

        sender_name: '',
        sender_city: '',
        sender_address: '',

        letter_full_name: '',
        letter_short_name: '',
        letter_address: '',

        translation: [],
        validation: [],
        validationTxt: [],
        validationTranslate: [],
        serverError: false,

        isFedexConsolidation: false,
        isDhlConsolidation: false,
        isLandmarkConsolidation: false,
        isShowInOrders: false,
        enable: false,

        optionsCountries: [{}],
      }
    },

    mounted() {

      let interval = setInterval(() => {
        if(
          (this.$store.getters.GET_LANGUAGES.length > 0 && Object.hasOwnProperty.call(this.$store.getters.getWarehousesItem, 'translationStorage')) ||
          interval > 10000
        ){
          this.initTranslationFields()
          clearInterval(interval)
        }
      }, 100)

      this.$store.dispatch('fetchCountries').then(() => {
        this.optionsCountries = this._.toArray(this.$store.getters.getCountries)
      })

      this.$store.dispatch('fetchWarehousesType')

      this.$store.dispatch('getWarehouses', this.$route.params.id)

    },

    beforeDestroy() {
      this.$store.commit('setWarehousesItem', {warehousesItem: {}})
    },

    methods: {

      translationFields() {
        return {

          name: '',

          warehouse_card_title: '',
          warehouse_card_representative: '',
          warehouse_card_address: '',
          warehouse_card_phone: '',
          warehouse_card_working_hours: '',
        }
      },

      changeCountry(val) {
        if(val === '') return this.selectedCountry = ''
        this.selectedCountry = val;
      },

      changeTypeWarehouse(val) {
        if(val === '') return this.selectedType = ''
        this.selectedType = val;
      },

      initTranslationFields() {

        this.selectedType = this.$store.getters.getWarehousesItem.type
        this.selectedCountry = this.$store.getters.getWarehousesItem.country
        this.selectedCountry.label = this.$store.getters.getWarehousesItem.country.name
        this.code = this.$store.getters.getWarehousesItem.code
        this.customSort = this.$store.getters.getWarehousesItem.sort
        this.zipCode = this.$store.getters.getWarehousesItem.sender_postcode
        this.email = this.$store.getters.getWarehousesItem.sender_email
        this.phone = this.$store.getters.getWarehousesItem.sender_phone
        this.isDhlConsolidation = this.$store.getters.getWarehousesItem.consolidation_dhl
        this.isFedexConsolidation = this.$store.getters.getWarehousesItem.consolidation_fedex
        this.isLandmarkConsolidation = this.$store.getters.getWarehousesItem.consolidation_landmark
        this.isShowInOrders = this.$store.getters.getWarehousesItem.show_in_orders

        this.sender_name = this.$store.getters.getWarehousesItem.sender_name !== null ? this.$store.getters.getWarehousesItem.sender_name : ''
        this.sender_address = this.$store.getters.getWarehousesItem.sender_address !== null ? this.$store.getters.getWarehousesItem.sender_address : ''
        this.sender_middle_name = this.$store.getters.getWarehousesItem.sender_middle_name !== null ? this.$store.getters.getWarehousesItem.sender_middle_name : ''
        this.sender_last_name = this.$store.getters.getWarehousesItem.sender_last_name !== null ? this.$store.getters.getWarehousesItem.sender_last_name : ''
        this.sender_city = this.$store.getters.getWarehousesItem.sender_city !== null ? this.$store.getters.getWarehousesItem.sender_city : ''
        this.sender_street = this.$store.getters.getWarehousesItem.sender_street !== null ? this.$store.getters.getWarehousesItem.sender_street : ''
        this.sender_building = this.$store.getters.getWarehousesItem.sender_building !== null ? this.$store.getters.getWarehousesItem.sender_building : ''
        this.sender_flat = this.$store.getters.getWarehousesItem.sender_flat !== null ? this.$store.getters.getWarehousesItem.sender_flat : ''

        this.letter_full_name = this.$store.getters.getWarehousesItem.letter_full_name
        this.letter_short_name = this.$store.getters.getWarehousesItem.letter_short_name
        this.letter_address = this.$store.getters.getWarehousesItem.letter_address

        this.enable = this.$store.getters.getWarehousesItem.enable

        this.$store.getters.GET_LANGUAGES.map((item) => {

          let translate = {}
          Object.keys(this.translationFields()).map(itemField => {

            translate[itemField] = this.$store.getters.getWarehousesItem.translationStorage[item.locale][itemField] !== undefined ?
              this.$store.getters.getWarehousesItem.translationStorage[item.locale][itemField] : ''

            this.validation[itemField + '_' + item.id] = false
            this.validationTxt[itemField + '_' + item.id] = ''
            this.validationTranslate[itemField + '_' + item.id] = false
          })
          this.translation.push(translate)
        })

      },


      save() {

        let validationItems = {}
        let translationArray = {}
        let data = {}
        let validationOptions = {}

        this.translation.map((item, index) => {
          translationArray[this.$store.getters.GET_LANGUAGES[index].id] = {}

          Object.keys(this.translationFields()).map(itemField => {
            translationArray[this.$store.getters.GET_LANGUAGES[index].id][itemField] = item[itemField]

            validationItems[itemField + '_' + this.$store.getters.GET_LANGUAGES[index].id] = item[itemField]
            validationOptions[itemField + '_' + this.$store.getters.GET_LANGUAGES[index].id] = {type: ['empty']}
          })

        })

        validationItems.zipCode = this.zipCode
        validationItems.phone = this.phone
        validationItems.email = this.email
        validationItems.code = this.code
        validationItems.customSort = this.customSort
        validationItems.selectedCountry = this.selectedCountry
        validationItems.selectedType = this.selectedType.id

        validationItems.sender_name = this.sender_name
        validationItems.sender_address = this.sender_address
        validationItems.sender_city = this.sender_city
        validationItems.letter_full_name = this.letter_full_name
        validationItems.letter_short_name = this.letter_short_name
        validationItems.letter_address = this.letter_address

        validationOptions.zipCode = {
          type: [
            'empty'
          ]
        }
        validationOptions.phone = validationOptions.zipCode
        validationOptions.email = {
          type: [
            'empty',
            'email'
          ]
        }
        validationOptions.code = validationOptions.zipCode
        validationOptions.customSort = {
          type: [
            'empty',
            'numeric'
          ]
        }
        validationOptions.selectedCountry = validationOptions.zipCode
        validationOptions.selectedType = validationOptions.zipCode
        validationOptions.sender_name = validationOptions.zipCode
        validationOptions.sender_address = validationOptions.zipCode
        validationOptions.sender_city = validationOptions.zipCode
        validationOptions.letter_full_name = validationOptions.zipCode
        validationOptions.letter_short_name = validationOptions.zipCode
        validationOptions.letter_address = validationOptions.zipCode


        console.log(validationItems);
        console.log(validationOptions);

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError





        if(validate.isValidate){


          data = {
            "type_id": this.selectedType.id,
            "country_id": this.selectedCountry.id,
            "code": this.code,
            "sort": this.customSort,

            "sender_name": this.sender_name,
            "sender_address": this.sender_address,
            "sender_city": this.sender_city,

            "sender_postcode": this.zipCode,
            "sender_email": this.email,
            "sender_phone": this.phone,

            "letter_full_name": this.letter_full_name,
            "letter_short_name": this.letter_short_name,
            "letter_address": this.letter_address,

            "consolidation_dhl": this.isDhlConsolidation,
            "consolidation_fedex": this.isFedexConsolidation,
            "consolidation_landmark": this.isLandmarkConsolidation,
            "show_in_orders": this.isShowInOrders,
            "enable": this.enable,


          }


          data.translations = translationArray

          this.serverError = false;

          this.$store.dispatch('updateWarehouses', {id: this.$route.params.id, data: data}).then((response) => {

            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              console.log(response);

              setTimeout(() => {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$router.push(this.$store.getters.GET_PATHS.mainSettingsWarehouseManagement)
              }, 200)

            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {

              let errors = response.response.data.errors;


              this.serverError = true;
              errors.type_id ? (this.validation.selectedType = true, this.validationTxt.selectedType = errors.type_id[0]) : false;
              errors.sender_email ? (this.validation.email = true, this.validationTxt.email = errors.sender_email[0]) : false;
              errors.sender_address ? (this.validation.sender_address = true, this.validationTxt.sender_address = errors.sender_address[0]) : false;
              errors.sort ? (this.validation.customSort = true, this.validationTxt.customSort = errors.sort[0]) : false;
              errors.code ? (this.validation.code = true, this.validationTxt.code = errors.sort[0]) : false;
              errors.letter_full_name ? (this.validation.letter_full_name = true, this.validationTxt.letter_full_name = errors.letter_full_name[0]) : false;
              errors.letter_short_name ? (this.validation.letter_short_name = true, this.validationTxt.letter_short_name = errors.letter_short_name[0]) : false;
              errors.letter_address ? (this.validation.letter_address = true, this.validationTxt.letter_address = errors.letter_address[0]) : false;
            }

          })
        }
      },


    }
  }
</script>

<style scoped>

</style>
