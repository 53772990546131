<template>
  <div class="detail-page">
    <!--<div class="detail-page__left">-->
      <!--<CardLeftBlock-->
        <!--:name="'Warehouse Management'"-->
        <!--:value="'Creation'"-->
      <!--/>-->
    <!--</div>-->
    <div class="detail-page__right">
      <WarehouseManagementCreation/>
    </div>
  </div>
</template>

<script>
  import WarehouseManagementCreation from "./WarehouseManagementCreation/WarehouseManagementCreation";
  // import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";

  export default {
    name: "WarehouseManagementCreationPage",
    components: {
      WarehouseManagementCreation,
      // CardLeftBlock,
    },
  }
</script>

<style scoped>

</style>
